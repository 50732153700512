import React, { useEffect, useState } from 'react';
import { GlobalProps } from '@lib/global';
import { getLocalImageUrl } from '@lib/helpers/assetsHelpers';
import TopMenuItem from '@components/Molecules/TopMenuItem';
import Logo from '@app/assets/images/costlocker-logo-full-color.svg';
import { Container, Nav, Navbar, Offcanvas } from 'react-bootstrap';
import Link from 'next/link';

export type Props = {
  globalProps: GlobalProps;
};

export default function Header({ globalProps }: Props) {
  const state = {
    activeMenuHoverItems: 0,
  };

  const [isScrolled, setScrolled] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const handleScroll = () => {
    if (window.scrollY < 120) {
      setScrolled(false);
    } else if (window.scrollY > 200) {
      setScrolled(true);
    }
  };

  const { translations: translate } = globalProps;

  const topMenu = translate.menus['topMenu'].items;

  let announcementElement = null;

  if (globalProps.globals.data.attributes.DisplayAnnouncement) {
    announcementElement = (
      <div
        className={`top-promo  ${isScrolled && 'scrolled'} `}
        dangerouslySetInnerHTML={{
          __html: globalProps.globals.data.attributes.Announcement.replaceAll(
            /__(.*?)__/g,
            "<span class='text-highlight-blue'>$1</span>"
          ),
        }}
      ></div>
    );
  }

  const menuItems = topMenu.map((item, index) => {
    return <TopMenuItem key={index} globalProps={globalProps} menu={item} />;
  });

  const expand = 'sm';

  return (
    <div className="nav-container sticky-top">
      {announcementElement}

      <Navbar
        className={`sticky-top bg-lightest header-container ${isScrolled && 'header-scrolled'}`}
        expand="lg"
        style={
          {
            // backgroundImage: `url('${getLocalImageUrl("icons.png")}')`,
          }
        }
      >
        <Container>
          <Navbar.Brand title="Costlocker" href={translate.webUrl}>
            <Logo style={{ width: '100%' }}></Logo>
          </Navbar.Brand>
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            placement="end"
            className="offcanvas-menu main-menu"
            style={{
              backgroundImage: `url('${getLocalImageUrl('icons.png')}')`,
            }}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                <Navbar.Brand href={translate.webUrl}>
                  <Logo style={{ width: '150px' }}></Logo>
                </Navbar.Brand>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav>
                {menuItems}

                <div className="nav-item d-inline-block d-lg-none">
                  <Link className="nav-link btn btn-primary" href={translate.loginUrl}>
                    {translate.buttons.login}
                  </Link>
                </div>
                <div className="nav-item d-inline-block d-lg-none">
                  <Link className="nav-link btn btn-secondary" href={translate.signupUrl}>
                    {translate.buttons.signup}
                  </Link>
                </div>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
          <Nav className="main-menu  ms-auto me-3">
            <li className="nav-item">
              <Link
                className="nav-link d-none d-lg-block fw-bold"
                style={{ color: '#003366' }}
                href={translate.loginUrl}
              >
                {translate.buttons.login}
              </Link>
            </li>
            <li className="nav-item nav-signup">
              <Link className="nav-link btn btn-secondary text-white fw-bold" href={translate.signupUrl}>
                {translate.buttons.signup}
              </Link>
            </li>
          </Nav>
          <Navbar.Toggle />
        </Container>
      </Navbar>
    </div>
  );
}
