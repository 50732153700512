"use client";

import { ThemeProvider } from "styled-components";
import React from "react";
import { Themes } from "@components/Themes/Themes";
import { GlobalProps } from "@lib/global";
import Footer from "@components/Organisms/Footer";
import Header from "@components/Organisms/Header";
import { NextIntlClientProvider } from "next-intl";

export const PageLayout = (props: {
  children: any;
  globalProps: GlobalProps;
}) => {
  const { children, globalProps } = props;

  return (
    <ThemeProvider theme={Themes.normal}>
      <NextIntlClientProvider
        locale={globalProps.locale}
        messages={globalProps.translations as any}
      >
        <Header globalProps={globalProps}></Header>
        {children}
        <Footer globalProps={globalProps}></Footer>
      </NextIntlClientProvider>
    </ThemeProvider>
  );
};
