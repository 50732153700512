"use client";
import React, { useEffect } from "react";

export const Intercom = () => {
  useEffect(() => {
    (window as any)?.Intercom("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: "27081efbd031c55f4b546a81fb0d8b1cced61624",
    });
  });

  return <></>;
};
