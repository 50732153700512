import { GlobalProps } from '@lib/global';
import { MenuItemSchema } from '@lib/types/menuItem.schema';
import Link from 'next/link';
import Image from 'next/image';
import { getIconUrl, getImageUrl, getLocalImageUrl } from '@lib/helpers/assetsHelpers';
import React, { useState } from 'react';
import { usePathname } from 'next/navigation';
import { Nav, NavDropdown } from 'react-bootstrap';
import { ChevronDown } from 'react-feather';

export type Props = {
  globalProps: GlobalProps;
  menu: MenuItemSchema;
};

export default function TopMenuItem({ globalProps, menu }: Props) {
  const { translations: translate } = globalProps;
  const item = menu;

  const pathname = usePathname();

  const [state, setState] = useState({
    activeMenuHoverItem: 0,
  });
  const handleMouseEnter = (index: number) => (event: any) => {
    setState({ ...state, activeMenuHoverItem: index });
  };

  if (item.topMenuItem) {
    const submenuLinksElements = item.topMenuItem.map((child, indexLinks) => {
      return (
        <NavDropdown.Item
          key={'submenuLink-' + indexLinks}
          href={child.url ?? ''}
          className="big-link"
          onMouseEnter={handleMouseEnter(indexLinks)}
        >
          <Image alt="" width={18} height={20} src={getIconUrl(child.icon)} className="my-auto" />
          <div className="title">
            <span className="link">
              {child.title}
              <span className="d-none badge bg-{{ item.badgeColor }}">badge</span>
            </span>
            {/*<span className="span subtitle">{child.subtitle}</span>*/}
          </div>
        </NavDropdown.Item>
      );
    });

    const submenuContentElements = item.topMenuItem.map((child, indexContent) => {
      return (
        <div
          key={'submenuContent-' + indexContent}
          id={'menuItem-' + indexContent}
          className={'menu-item image-with-text ' + (state.activeMenuHoverItem === indexContent ? 'active' : '')}
        >
          <div className="content">
            <div className="flex-fill">
              <img alt="" src={getImageUrl(child.feature?.image?.url ?? '')} className="img-fit" />
            </div>
            <div className="p-3">
              <h3>{child.feature?.title}</h3>
              <p className="small">{child.feature?.text}</p>
              <Link href={child.feature?.buttonUrl ?? child.url ?? '#'} className="small">
                {child.feature?.buttonLabel}
                <Image alt="" src={getLocalImageUrl('_icons/ArrowRight.svg')} width={18} height={20} />
              </Link>
            </div>
          </div>
        </div>
      );
    });

    const title = (
      <span className="link-content">
        {item.title} &nbsp;
        <ChevronDown />
      </span>
    );

    return (
      <NavDropdown className="pt-0" title={title}>
        <div className="submenu">
          <div className="submenu-column p-3">{submenuLinksElements}</div>
          <div className="d-none block">{submenuContentElements}</div>
        </div>
      </NavDropdown>
    );
  } else {
    return (
      <Nav.Link className={'else ' + pathname === item.url ? 'active' : ''} href={item.url}>
        {item.title}
      </Nav.Link>
    );
  }
}
