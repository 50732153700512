import React from 'react';
import { GlobalProps } from '@lib/global';
import Image from 'next/image';
import { getLocalImageUrl } from '@lib/helpers/assetsHelpers';
import Link from 'next/link';
import { LinkSchema } from '@lib/types/transformed/LinkSchema';
import Logo from '@app/assets/images/costlocker-logo-full-white.svg';

export type Props = {
  globalProps: GlobalProps;
};

const linkMapper = (link: LinkSchema, index: number) => {
  return (
    <li key={index}>
      <Link target={link.newWindow ? '_blank' : ''} href={link.url}>
        {link.label}
      </Link>
    </li>
  );
};

export default function Footer({ globalProps }: Props) {
  const { translations: translate, footer } = globalProps;

  const col1 = footer.col1.links.map(linkMapper);
  const col2 = footer.col2.links.map(linkMapper);
  const col3 = footer.col3.links.map(linkMapper);
  const col4 = footer.col4.links.map(linkMapper);
  const col5 = footer.col5.links.map(linkMapper);

  const socials = [
    {
      label: 'Twitter',
      url: 'https://twitter.com/costlocker',
      icon: 'x.svg',
    },
    // {
    //   label: "Instagram",
    //   url: "https://www.instagram.com/costlocker",
    //   icon: "Instagram.svg",
    // },
    {
      label: 'LinkedIn',
      url: 'https://www.linkedin.com/company/costlocker',
      icon: 'linkedin.svg',
    },
    {
      label: 'YouTube',
      url: 'https://www.youtube.com/@costlocker8823',
      icon: 'youtubeNV.svg',
    },
    {
      label: 'Facebook',
      url: 'https://www.facebook.com/costlocker',
      icon: 'facebook.svg',
    },
  ].map((social, index) => {
    return (
      <div key={index} className="pe-3">
        <Link title={social.label} target="_blank" href={social.url}>
          <Image
            className="img-logo"
            src={getLocalImageUrl('_icons/' + social.icon)}
            alt={social.label}
            width={33}
            height={33}
          />
        </Link>
      </div>
    );
  });

  return (
    <div id="footer" className="footer hides-hero-section">
      <div className="container-fluid col-24 col-lg-20 mx-auto">
        <div className="row text-lg-start">
          <div className="col-sm-24 col-lg-6 terms-div">
            <ul>
              <li>
                <div className="img-div">
                  <div className="img-logo">
                    <Logo
                      style={{
                        width: '130px',
                        height: 'auto',
                        marginTop: '3px',
                      }}
                    ></Logo>
                  </div>
                </div>
              </li>
              {col1}
            </ul>
          </div>
          <div className="col-sm-24 col-lg-4 company">
            <h5>{footer.col2.title}</h5>
            <ul>{col2}</ul>
          </div>
          <div className="col-sm-24 col-lg-4 resources">
            <h5>{footer.col3.title}</h5>
            <ul>{col3}</ul>
          </div>
          <div className="col-sm-24 col-lg-4 stories">
            <h5>{footer.col4.title}</h5>
            <ul>{col4}</ul>
          </div>
          <div className="col-sm-24 col-lg-6 last-column align-items-end upToDate">
            <h5>{footer.col5.title}</h5>
            <ul>{col5}</ul>
            <div className="socials col-24">
              <div className="d-flex pt-2 mx-auto ms-lg-0">{socials}</div>
            </div>
          </div>
        </div>
        <div className="row text-center">
          <p>&copy; {footer.copyright}</p>
        </div>
      </div>
    </div>
  );
}
